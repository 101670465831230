import React from "react";
import theme from "react-syntax-highlighter/styles/hljs/atom-one-light";
import js from "react-syntax-highlighter/languages/hljs/javascript";
import xml from "react-syntax-highlighter/languages/hljs/xml";
import css from "react-syntax-highlighter/languages/hljs/css";

import SyntaxHighlighter, {
	registerLanguage,
} from "react-syntax-highlighter/light";

registerLanguage("javascript", js);
registerLanguage("xml", xml);
registerLanguage("css", css);

const CodePreview = ({ language = "javascript", children }) => {
	// const styles = {
	// 	background: "rgba(255, 255, 255, 0.02)",
	// 	borderRadius: 7,
	// 	padding: 25,
	// 	maxWidth: "100%",
	// 	width: "100%",
	// 	height: 370,
	// 	overflow: "auto",
	// 	lineHeight: 1.3,
	// 	margin: 0,
	// };

	return (
		<SyntaxHighlighter
			language={language}
			style={theme}
			// customStyle={styles}>
			className="codePreview">
			{children.trim()}
		</SyntaxHighlighter>
	);
};

export default CodePreview;
